import React, { useState, useRef } from 'react'
import "leaflet/dist/leaflet.css"
import { MapContainer, TileLayer, LayersControl, LayerGroup, useMapEvents, GeoJSON } from 'react-leaflet'
import { API, Auth } from "aws-amplify";
import L from "leaflet"
import iconStore from "../assets/icon_store.png"
import { RotatingLines } from 'react-loader-spinner'

function DataFilterAndDisplay({ initialDataInfo }) {
    const layerControlRef = useRef(null);
    const [dataInfo, setDataInfo] = useState(initialDataInfo)
    const [popupFlag, setPopupFlag] = useState(false);
    const [isAPICalling, setIsAPICalling] = useState(false);

    return (
        <div className="main">
            <Legend dataInfo={dataInfo} />
            <Popup popupFlag={popupFlag} dataInfo={dataInfo} />
            <RotatingLines
                strokeColor="grey"
                strokeWidth="5"
                animationDuration="1"
                width="22"
                visible={isAPICalling}
                ariaLabel='loading'
            />
            <MapContainer center={[35.6647, 139.7378]} zoom={17} minZoom={1} scrollWheelZoom={true} zoomControl={false} >
                <a href="http://mapbox.com/about/maps" class='mapbox-logo' target="_blank" rel="noreferrer">Mapbox</a>
                <TileLayerSetting />
                <MapMoveListener layerControlRef={layerControlRef} setDataInfo={setDataInfo} dataInfo={dataInfo} setPopupFlag={setPopupFlag} setIsAPICalling={setIsAPICalling} isAPICalling={isAPICalling} />
                <LayersControlSetting dataInfo={dataInfo} layerControlRef={layerControlRef} isAPICalling={isAPICalling} />
            </MapContainer>
        </div >
    )
}

function Popup({ popupFlag, dataInfo }) {
    // const hasTrue = dataInfo.slice(1).map(item => item.checked).includes(true);
    if (popupFlag) {
        return (<div className='popup'>データ表示の為に、ズームインをしてください</div>)
    }
};

function TileLayerSetting() {
    return (
        <TileLayer
            attribution="© <a href='https://www.mapbox.com/about/maps/'>Mapbox</a> © <a href='http://www.openstreetmap.org/copyright'>OpenStreetMap</a> <strong><a href='https://www.mapbox.com/map-feedback/' target='_blank'>Improve this map</a></strong>"
            url={`https://api.mapbox.com/styles/v1/v-ilin-ga/clmsv1dvg01ze01r640lh7uu4/tiles/{z}/{x}/{y}?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`}
            tileSize={512}
            zoomOffset={-1}
        />
        // grayscale tile
        // <TileLayer
        //     attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        //     url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
        // />
    )
}

function Legend({ dataInfo }) {
    const imgList = dataInfo.filter((item) => item.checked && item.buttonType === 'radio' && item.imgPath !== "")
    return (
        <div className="legends" >
            {imgList.map((item, index) => {
                return (<img className="img_legends" src={`assets/legend/${item.imgPath}`} alt="react logo" key={index} />)
            })}
        </div>
    )
}

function MapMoveListener({ layerControlRef, setDataInfo, dataInfo, setPopupFlag, setIsAPICalling, isAPICalling }) {
    const callLambda = async (bounds, table) => {
        // console.log("this is call lambda")
        let response = "";
        // const user = await Auth.currentAuthenticatedUser();
        // const token = user.signInUserSession.idToken.jwtToken;
        const apiName = "datascope"
        const path = 'fetch_data';
        const myInit = {
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession())
                    .getAccessToken()
                    .getJwtToken()}`,
            },
            body: {
                table: table,
                bounds: bounds,
            },
        };
        await API.post(apiName, path, myInit)
            .then(tmpResponse => {
                response = tmpResponse;
            })
            .catch(error => {
                console.log(error)
            })
        return response;
    };

    const fetchData = async (layerControlRef, map, setDataInfo, dataInfo, setIsAPICalling) => {
        let tmpDataInfo = [...dataInfo];
        let isChceked = dataInfo.map(item => item.checked);

        // zoom <13 creates a payload issue (lambda cannot return data more than 6 MB)
        console.log(isAPICalling)
        if (map.getZoom() > 13 && !isAPICalling) {
            setIsAPICalling(true)
            setPopupFlag(false);
            const coor = map.getBounds();
            const bounds = [coor._northEast.lng, coor._northEast.lat, coor._southWest.lng, coor._southWest.lat];

            //  get checked status
            isChceked = layerControlRef.current._layerControlInputs.map(e => e.checked);
            const finalData = await Promise.all(isChceked.map(async (checked, index) => {
                if (checked && index !== 0 && !isAPICalling) {

                    const table = dataInfo[index].table;
                    const response = await callLambda(bounds, table);
                    if (response === "") {
                        setPopupFlag(true);
                    } else {
                        setPopupFlag(false);
                        tmpDataInfo[index].checked = isChceked[index]
                        tmpDataInfo[index].loadedData = response
                    }
                    // setIsAPICalling(false)
                    return response
                } else {
                    tmpDataInfo[index].checked = false
                    tmpDataInfo[index].loadedData = [];
                    return []
                }
            }));
            setIsAPICalling(false)
        } else {
            if (!isAPICalling) {
                tmpDataInfo.forEach((item, index) => {
                    item.loadedData = [];
                    // item.checked = isChceked[index]
                }
                )
                setPopupFlag(true);
            }

        };

        setDataInfo(tmpDataInfo);
    };

    const map = useMapEvents(
        {
            moveend(e) {
                // console.log("map moved")
                fetchData(layerControlRef, map, setDataInfo, dataInfo, setIsAPICalling);
            },
            baselayerchange(e) {
                // console.log("baselayer changed");
                fetchData(layerControlRef, map, setDataInfo, dataInfo, setIsAPICalling);
            },
            overlayadd(e) {
                // console.log("overlay added");
                fetchData(layerControlRef, map, setDataInfo, dataInfo, setIsAPICalling);
            },
            overlayremove(e) {
                // console.log("overlay removed");
                fetchData(layerControlRef, map, setDataInfo, dataInfo, setIsAPICalling);
            }
        }
    );
}

function LayersControlSetting({ dataInfo, layerControlRef }) {
    const baselayData = dataInfo.filter((item) => (item.buttonType === "radio"));
    const overlayData = dataInfo.filter((item) => (item.buttonType === "check"));

    const styleGeoJson = (feature, type, color) => {
        let fillColor = "#ffd900";
        let outlineColor = "#f3f3f3";
        if (type === "polygon") {
            if (color.startsWith("#")) {
                fillColor = color;
            } else {
                fillColor = feature.properties[color];
            }
            return {
                "color": outlineColor,
                "weight": 2,
                "opacity": 0.3,
                "fillColor": fillColor,
                "fillOpacity": 0.5,
            }
        } else if (type === "line") {
            if (color.startsWith("#")) {
                outlineColor = color;
            } else {
                outlineColor = feature.properties[color];
            }
            return {
                "color": outlineColor,
                "weight": 2.5,
                "opacity": 1,
            }
        } else if (type === "point") {
            return NaN
        }
    };

    const addCallBox = (feature, layer, callbox) => {
        if (callbox !== "") {
            let callboxText = `${feature.properties[callbox]}`;
            switch (callbox) {
                // 路線ネットワークの場合
                case "lineName":
                    const lineName = {
                        transfer: '乗り換え',
                        interStation: '駅間',
                        stationToPlatform: '駅とホームの間',
                        platformToStation: '駅とホームの間'
                    }
                    if (callboxText === "null") {
                        if (feature.geometry.type === "Point") {
                            callboxText = `${feature.properties.stationName}駅`;
                        } else if (feature.geometry.type === "LineString") {
                            // console.log(feature.properties.modality)
                            const name = lineName[feature.properties.modality]
                            callboxText = `${name}`;
                        }
                    }
                    break
                case "pop_density":
                    callboxText = `${parseInt(callboxText)}`
                    break
                default:
                    break
            }
            layer.bindPopup(callboxText);
        }
    };

    const addPointIcon = (feature, latlng, name) => {
        const iconPathHash = {
            amenity: 'leisure.png',
            art: 'art.png',
            bank: 'bank.png',
            bar: 'bar.png',
            beauty: 'beauty.png',
            bicycle_parking: 'bicycle_parking.png',
            cafe: 'cafe.png',
            car_parking: 'car_parking.png',
            cinema: 'cinema.png',
            clinic: 'clinic.png',
            community: 'community.png',
            convenience_store: 'convenience_store.png',
            fire_station: 'fire_station.png',
            fitness: 'fitness.png',
            funeral: 'funeral.png',
            gambling: 'gambling.png',
            gas_station: 'gas_station.png',
            hospital: 'hospital.png',
            hostel: 'hotel.png',
            hotel: 'hotel.png',
            laundry: 'laundry.png',
            leisure: 'leisure.png',
            love_hotel: 'love_hotel.png',
            motel: 'hotel.png',
            motorcycle_parking: 'motorcycle_parking.png',
            nursing_home: 'nursing_home.png',
            office: 'office.png',
            park: 'park.png',
            pharmacy: 'pharmacy.png',
            police: 'police.png',
            post_office: 'post_office.png',
            post_secondary_school: 'education.png',
            "primary-school": 'school.png',
            pub: 'pub.png',
            recreation: 'leisure.png',
            religion: 'religion.png',
            research_institute: 'science.png',
            restaurant: 'eat.png',
            school: 'school.png',
            science: 'science.png',
            service: 'service.png',
            shopping: 'shopping.png',
            smoking_area: 'smoking.png',
            spa: 'spa.png',
            specialty_food: 'specialty_food.png',
            specialty_school: 'school.png',
            supermarket: 'supermarket.png',
            vending_machine: 'vending_machine.png'
        }
        let iconPath = iconStore;
        let iconSize = [15, 15];
        switch (name) {
            case "路線ネットワーク":
                switch (feature.properties.modality) {
                    case "platform":
                        iconPath = "/assets/iconsPoints/platform.png";
                        break;
                    case "station":
                        iconPath = "/assets/iconsPoints/station.png";
                        break;
                    case "transfer":
                        iconSize = [0, 0];
                        break
                    case "through":
                        iconSize = [0, 0];
                        break
                    default:
                        iconSize = [0, 0];
                        break
                }
                break;
            case "飲食店・その他施設":
                iconPath = `/assets/iconsPoints/${iconPathHash[feature.properties.subcategory]}`;
                break;
            default:
                break;
        }
        return L.marker(latlng, {
            icon: L.icon({
                iconUrl: iconPath,
                iconSize: iconSize
            })
        })
    }

    const generateNameCitation = (name, citation) => {
        var finalName = ""
        if (citation.length > 0) {
            finalName = `${name} <a href=${citation} target="_blank" ><div class="hyper"><img src="/assets/iconsPoints/external-link.svg" alt="サンプル" class="external"></div></a>`
        } else {
            finalName = `${name}`
        }
        return finalName
    }

    const ShowBaselay = ({ baselayData }) => {
        return (
            <>
                {baselayData.map((item, index) => (
                    < LayersControl.BaseLayer name={generateNameCitation(item.name, item.citation)} key={index} checked={item.checked} >
                        <LayerGroup >
                            <GeoJSON data={item.loadedData}
                                style={(future) => styleGeoJson(future, item.type, item.color)}
                                onEachFeature={(feature, layer) => { return addCallBox(feature, layer, item.callbox) }} />
                        </LayerGroup>
                    </LayersControl.BaseLayer>
                )
                )}
            </>
        )
    };

    const ShowOverlay = ({ overlayData }) => {
        return (
            <>
                {overlayData.map((item, index) => (
                    < LayersControl.Overlay name={generateNameCitation(item.name, item.citation)} key={index} checked={item.checked} >
                        <LayerGroup>
                            <GeoJSON
                                data={item.loadedData}
                                style={(future) => styleGeoJson(future, item.type, item.color)}
                                onEachFeature={(feature, layer) => { return addCallBox(feature, layer, item.callbox) }}
                                pointToLayer={(feature, latlng) => { return addPointIcon(feature, latlng, item.name) }} />
                        </LayerGroup>
                    </LayersControl.Overlay >
                ))}
            </>
        )
    };
    return (
        <>
            < LayersControl position="topleft" collapsed={false} ref={layerControlRef} >
                <ShowBaselay baselayData={baselayData} />
                <ShowOverlay overlayData={overlayData} />
            </LayersControl >
        </>
    )
}

const DATA = [
    // polygon for radio button
    { buttonType: "radio", name: "クリアデータ", checked: false, type: "", table: "", loadedData: [], imgPath: "", citation: "" },
    { buttonType: "radio", name: "土地の使用", checked: false, type: "polygon", table: "land_use", loadedData: [], imgPath: "legend_landuse.png", callbox: "landUse", color: "landUse_color", citation: "https://nlftp.mlit.go.jp/ksj/gml/datalist/KsjTmplt-L03-b-v3_1.html" },
    { buttonType: "radio", name: "建物", checked: false, type: "polygon", table: "buildings", loadedData: [], imgPath: "", callbox: "", color: "#666666", citation: "https://fgd.gsi.go.jp/download/menu.php" },
    { buttonType: "radio", name: "従業者数", checked: false, type: "polygon", table: "economic", loadedData: [], imgPath: "legend_num_employees.png", callbox: "num_employees", color: "num_employees_color", citation: "https://www.e-stat.go.jp/gis/statmap-search?page=5&type=1&toukeiCode=00200551&toukeiYear=2006&aggregateUnit=H&serveyId=H002005112006&statsId=T000389" },
    { buttonType: "radio", name: "事業所数", checked: false, type: "polygon", table: "economic", loadedData: [], imgPath: "legend_num_offices.png", callbox: "num_offices", color: "num_offices_color", citation: "https://www.e-stat.go.jp/gis/statmap-search?page=5&type=1&toukeiCode=00200551&toukeiYear=2006&aggregateUnit=H&serveyId=H002005112006&statsId=T000389" },
    { buttonType: "radio", name: "人口密度（㎢）", checked: false, type: "polygon", table: "population", loadedData: [], imgPath: "legend_pop_density.png", callbox: "pop_density", color: "pop_density_color", citation: "https://www.e-stat.go.jp/gis/statmap-search?page=1&type=1&toukeiCode=00200521&toukeiYear=2015&aggregateUnit=A&serveyId=A002005212015&statsId=T000849" },
    { buttonType: "radio", name: "総人口", checked: false, type: "polygon", table: "population", loadedData: [], imgPath: "legend_pop_total.png", callbox: "pop_total", color: "pop_total_color", citation: "https://www.e-stat.go.jp/gis/statmap-search?page=1&type=1&toukeiCode=00200521&toukeiYear=2015&aggregateUnit=A&serveyId=A002005212015&statsId=T000849" },
    { buttonType: "radio", name: "植生", checked: false, type: "polygon", table: "vegetation", loadedData: [], imgPath: "legend_vegetation.png", callbox: "category", color: "category_color", citation: "https://www.biodic.go.jp/kiso/vg/vg_kiso.html" },
    { buttonType: "radio", name: "用途地域", checked: false, type: "polygon", table: "zoning", loadedData: [], imgPath: "legend_zoning.png", callbox: "zoneType", color: "zoneType_color", citation: "https://nlftp.mlit.go.jp/ksj/gml/datalist/KsjTmplt-A29-v2_1.html#prefecture11" },
    // { buttonType: "radio", name: "歩道", checked: false, type: "polygon", table: "blocks", loadedData: [], imgPath: "", callbox: "", color: "", citation: "https://fgd.gsi.go.jp/download/menu.php" },

    // line/point for check button
    { buttonType: "check", name: "市区町村エリア", checked: false, type: "line", table: "admin_boundaries", loadedData: [], imgPath: "", callbox: "addressName", color: "#3d3123", citation: "https://nlftp.mlit.go.jp/ksj/gml/datalist/KsjTmplt-N03-v2_4.html" },
    { buttonType: "check", name: "道路ネットワーク", checked: false, type: "line", table: "road_network_edges", loadedData: [], imgPath: "", callbox: "road_type", color: "#e60033", citation: "https://www.openstreetmap.org/#map=10/35.6696/139.8587" },
    { buttonType: "check", name: "路線ネットワーク", checked: false, type: "line", table: "train", loadedData: [], imgPath: "", callbox: "lineName", color: "color", citation: "https://www.openstreetmap.org/#map=10/35.6696/139.8587" },
    { buttonType: "check", name: "飲食店・その他施設", checked: false, type: "point", table: "stores", loadedData: [], imgPath: "", callbox: "storeName", color: "", citation: "https://www.openstreetmap.org/#map=10/35.6696/139.8587" },
];

export default function MapPart() {
    return <DataFilterAndDisplay initialDataInfo={DATA} />;
}