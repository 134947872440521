import datascope_logo from '../assets/datascope_logo_v1.png'
import singout from "../assets/sign-out.svg"
import aiscLogo from "../assets/AISC_logo_only_v1l.png"
import gaLogo from "../assets/GA_logo.png"

function HeaderSetting({ signOut }) {
    // const username = user.attributes.email.split("@")[0];

    return (
        <div className="header">
            <img className="datascope" src={datascope_logo} alt="datascopeLogo" />
            <a href='https://aisc.ga-tech.co.jp/'>
                <img className="headerLogo" src={aiscLogo} url="https://aisc.ga-tech.co.jp/" alt="aiscLogo" />
            </a>
            <a href='https://www.ga-tech.co.jp/'>
                <img className="headerLogo" src={gaLogo} url="https://aisc.ga-tech.co.jp/" alt="gaLogo" />
            </a>
            <img className='eaderRight singoutlogo' onClick={signOut} src={singout} alt="signoutLogo" />
        </div>
    )
}

export default function Header({ signOut }) {
    return <HeaderSetting signOut={signOut} />;
}