import '@aws-amplify/ui-react/styles.css';
// import { withAuthenticator } from '@aws-amplify/ui-react';
import MapPart from './components/MapPart';
import Header from './components/Header';


function App({ signOut }) {
  return (
    <div >
      <main>
        <Header signOut={signOut} />
        <MapPart />
      </main>
    </div >
  );
}

export default (App);