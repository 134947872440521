import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Amplify, Auth } from "aws-amplify";
import awsconfig from "./aws-exports";
import { Authenticator, Text, View, useTheme } from '@aws-amplify/ui-react'


const redirectUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
Amplify.configure({
  ...awsconfig,
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    region: "ap-northeast-1",
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    cookieStorage: {
      domain: `.${process.env.REACT_APP_CUSTOM_DOMAIN}`,
    },
    oauth: {
      domain: process.env.REACT_APP_OAUTH_DOMAIN,
      scope: [
        "phone",
        "email",
        "openid",
        "profile",
        "aws.cognito.signin.user.admin",
      ],
      redirectSignIn: redirectUrl,
      redirectSignOut: redirectUrl,
      responseType: "code",
    },
  },
  API: {
    endpoints: [
      {
        name: 'datascope',
        endpoint: process.env.REACT_APP_API_ENDPOINT,
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getAccessToken()
              .getJwtToken()}`,
          };
        },
      },
    ],
  },
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Authenticator
      socialProviders={['google']}
      hideSignUp={true}
      components={{
        Footer() {
          const { tokens } = useTheme();
          return (
            <View textAlign="center" padding={tokens.space.large}>
              <Text color={tokens.colors.neutral[80]}>
                ログインすると、
                <a
                  href={`https://${process.env.REACT_APP_CUSTOM_DOMAIN
                    }/term_of_service.pdf`}
                  target="_blank"
                  rel="noreferrer"
                >
                  利用規約
                </a>
                に同意したものとします
              </Text>
              <Text color={tokens.colors.neutral[80]}>
                &copy; GA technologies AI Strategy Center. All Rights Reserved
              </Text>
            </View>
          );
        },
        SignIn: {},
      }}>
      {({ signOut }) => (
        <App signOut={signOut} />
      )}
    </Authenticator>
  </React.StrictMode >,
);
